<template>
  <div class="flex flex-col gap-y-4">
    <BaseHeading
      as="h2"
      class="text-muted-700 dark:text-muted-400"
    >
      Kolommetje hier
    </BaseHeading>

    <TextParagraph color="light">
      We moeten nog even bedenken wat we hier precies gaan neerzetten en hoe we
      deze kolom kunnen configureren.
    </TextParagraph>
  </div>
</template>
